.lane_color_0 {
  background-color: var(--fc-lane-color-even-line);
}

.lane_color_1 {
  background-color: var(--fc-lane-color-odd-line); /* Replace 'your_desired_color' with your desired color, e.g., #d4e7ff */
}

.fc {
  display: flex !important;
  flex-direction: column !important;
  flex: 1 1 auto !important;
}

.fc-view-harness {
  display: flex !important;
  flex-direction: column !important;
  flex: 1 1 auto !important;
}

.fc-timeline-slot-label:is(
  .fc-day-sat,
  .fc-day-sun,
  .fc-holy-day
) {
  --fc-bg-label-days: var(--fc-bg-label-unavailable-days);
  --fc-color-label-color: var(--fc-color-label-unavailable-color);
}

.fc-timeline-slot-lane {
  background-color: var(--fc-bg-lane-days);
  color: var(--fc-color-lane-color);
}

.fc-timeline-slot-lane:is(
  .fc-day-sat,
  .fc-day-sun,
  .fc-holy-day
) {
  --fc-bg-lane-days: var(--fc-bg-lane-unavailable-days);
  --fc-color-lane-color: var(--fc-color-lane-unavailable-color);
}

.fc-day-today {
  background-color: #f0f3;
}

.resource-font-size-1_1em > div > div > span {
  font-size: 1.1em !important;
}

.side-title > div > div{
  writing-mode: vertical-rl;
  rotate: 180deg;
  text-orientation: mixed;
  position: sticky;
  font-weight: bolder;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-overflow-ellipsis: 1;
}

.fc-timeline-slot-label {
  background-color: var(--fc-bg-label-days, #0000);
}

.fc-timegrid-slot-label-text {
  color: var(--fc-color-label-color, black)
}

.fullcalendar-z-index {
  z-index: 100;
}

.fc-button-primary:hover {
  background-color: var(--fc-button-hover-bg-color);
  border-color: var(--fc-button-hover-border-color);
  color: var(--fc-button-hover-text-color);
}

.fc-button-primary:active {
  background-color: var(--fc-button-active-bg-color);
  border-color: var(--fc-button-active-border-color);
  color: var(--fc-button-active-text-color);
}

.backcolor-resource-total-euro {
  background: var(--bg-resource-total-euro);
  color: var(--color-resource-total-euro, white);
}
.backcolor-resource-media-fee {
  background: var(--bg-resource-media-fee);
  color: var(--color-resource-media-fee, white);
}
.backcolor-resource-discount-euro {
  background: var(--bg-resource-discount-euro);
  color: var(--color-resource-discount-euro, white);
}
.backcolor-resource-total-hls {
  background: var(--bg-resource-total-hls);
  color: var(--color-resource-total-hls, white)
}

.min-height-50px {
  height: 50px;  
}

.border-top-dark {
  border-top-color: black;
}

.flex-6 {
  flex: 4 1 auto;
}

.fragile-modal-xxl {
  --bs-modal-width: 1700px;
}